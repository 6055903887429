import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AutenticacaoService } from '../shared/autenticacao.service';
import { Router } from '@angular/router';
import { TOKEN, USER, UNID, VERSION } from 'src/app/app.api';
import { MessageService } from 'primeng/api';
import { ErrorMsg } from 'src/app/shared/models/errorMsg';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  constructor(private auth: AutenticacaoService, private router: Router, private msg: MessageService) { }
  version = VERSION;
  formulario: UntypedFormGroup = new UntypedFormGroup({
    login: new UntypedFormControl('', [Validators.required]),
    senha: new UntypedFormControl('', Validators.required)
  });
  errorMsg: ErrorMsg;
  bLoadEntrar: boolean = false;

  ngOnInit() {
  }

  onSubmit() {
    this.errorMsg = null;
    this.bLoadEntrar = true;
    this.auth.login(this.formulario.value).subscribe(
      resp => {
        if (resp.multi) {
          localStorage.setItem(TOKEN, resp.token);
          localStorage.setItem(USER, JSON.stringify(resp.usuario));
          this.router.navigateByUrl('escolher-unidade', { skipLocationChange: true }).then(
            () => this.router.navigate(['escolher-unidade']))
        } else {
          localStorage.setItem(TOKEN, resp.token);
          localStorage.setItem(USER, JSON.stringify(resp.usuario));
          localStorage.setItem(UNID, JSON.stringify(resp.unidade));
          this.router.navigate(['']);
        }
        this.bLoadEntrar = false;
      },
      error => {
        this.bLoadEntrar = false;
        this.actionError(error);
      }
    );
  }

  actionError(error) {
    this.errorMsg = {msg:error.statusText,tipo:ErrorMsg.ERRO};
  }

  fecharErro() {
    this.errorMsg = null;
  }


}
