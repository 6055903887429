import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';

//primeNG
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { ListboxModule } from 'primeng/listbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FieldsetModule } from 'primeng/fieldset';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import {OrderListModule} from 'primeng/orderlist';

//meus componentes
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { TabelaListComponent } from './components/tabela-list/tabela-list.component';
import { ExibirMoedaPipe } from './exibirMoedaPipe';
import { ButtonComponent } from './components/button/button.component';
import { ErrormsgComponent } from './components/errormsg/errormsg.component';
import { InputComponent } from './components/input/input.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { DateComponent } from './components/date/date.component';
import { InputMoedaComponent } from './components/input-moeda/input-moeda.component';
import { InputGroupComponent } from './components/input-group/input-group.component';
import { InputMaskComponent } from './components/input-mask/input-mask.component';
import { InputNumeroComponent } from './components/input-numero/input-numero.component';
import { InputLoginComponent } from './components/input-login/input-login.component';
import { TabelaIndiceComponent } from './components/tabela-indice/tabela-indice.component';
import { InputAreaComponent } from './components/input-area/input-area.component';
import { EditorComponent } from './components/editor/editor.component';
import { DatemaskDirective } from './components/date/datemask.directive';
import { LogoComponent } from './components/logo/logo.component';
import { CabecalhoComponent } from './components/cabecalho/cabecalho.component';
import { TabelaGenericaComponent } from './components/tabela-generica/tabela-generica.component';
import { LoadingComponent } from './components/loading/loading.component';




@NgModule({
  declarations: [
    PageHeaderComponent,
    TabelaListComponent,
    ExibirMoedaPipe,
    ButtonComponent,
    ErrormsgComponent,
    InputComponent,
    DropdownComponent,
    MultiselectComponent,
    DateComponent,
    InputMoedaComponent,
    InputGroupComponent,
    InputMaskComponent,
    InputNumeroComponent,
    InputLoginComponent,
    TabelaIndiceComponent,
    InputAreaComponent,
    EditorComponent,
    DatemaskDirective,
    LogoComponent,
    CabecalhoComponent,
    TabelaGenericaComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    //primeNG
    ButtonModule,
    PanelModule,
    InputTextModule,
    InputMaskModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    CalendarModule,
    ToastModule,
    RadioButtonModule,
    CardModule,
    TooltipModule,
    InputNumberModule,
    DialogModule,
    OverlayPanelModule,
    InputTextareaModule,
    EditorModule,
    TabViewModule,
    ListboxModule,
    ProgressSpinnerModule,
    FieldsetModule,
    MessageModule,
    MessagesModule,
    OrderListModule

  ],
  exports: [
    CommonModule,
    RouterModule,
    PageHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    TabelaListComponent,
    ExibirMoedaPipe,
    ButtonComponent,
    ErrormsgComponent,
    InputNumeroComponent,
    InputLoginComponent,
    TabelaIndiceComponent,
    InputAreaComponent,
    EditorComponent,
    LogoComponent,
    CabecalhoComponent,
    TabelaGenericaComponent,
    LoadingComponent,
    //primeNG
    ButtonModule,
    PanelModule,
    InputTextModule,
    InputComponent,
    InputMaskComponent,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    DropdownComponent,
    MultiselectComponent,
    CheckboxModule,
    DateComponent,
    ToastModule,
    RadioButtonModule,
    CardModule,
    InputMoedaComponent,
    InputGroupComponent,
    TooltipModule,
    InputNumberModule,
    DialogModule,
    OverlayPanelModule,
    TabViewModule,
    ListboxModule,
    ProgressSpinnerModule,
    FieldsetModule,
    CalendarModule,
    DatemaskDirective,
    MessageModule,
    MessagesModule,
    OrderListModule
  ]
})
export class SharedModule { }
