import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Cols } from '../../models/entidade-generica';
import { UNID } from 'src/app/app.api';
import { Modelo } from 'src/app/pages/modelos/shared/modelo.model';


@Component({
  selector: 'app-tabela-list',
  templateUrl: './tabela-list.component.html',
  styleUrls: ['./tabela-list.component.scss']
})

export class TabelaListComponent implements OnInit {

  @Input('lista') objs: any[] = [];
  @Input() cols: Cols[] = [];
  @Input() loading = true;
  @Input() pesquisarPor = "Pesquisar por nome";
  @Input() resizableColumns = true;
  @Input() bLoadApagar: boolean = false;
  @Input() isDialog: boolean = false;
  @Output() excluir = new EventEmitter();
  @Output() showDialog = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  apagar(item: any) {
    this.excluir.emit(item);
  }

  mostrarDialog(item) {
    this.showDialog.emit(item);
  }

  isModelos(item: Modelo): boolean {
    if (item.classe != 'Modelo') {
      return true;
    }
    let uni: number = JSON.parse(localStorage.getItem(UNID)).id;
    if (uni == item.unidade) {
      return true;
    }
    return false;
  }

  mostrartudo(dt) {
    console.log(dt);
  }


}
