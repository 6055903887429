import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ErrorMsg } from 'src/app/shared/models/errorMsg';
import { FormValidation } from 'src/app/shared/validadores/formValidation';
import { AutenticacaoService } from '../shared/autenticacao.service';

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.css']
})
export class EsqueciSenhaComponent implements OnInit {

  formulario: UntypedFormGroup = new UntypedFormGroup({
    matricula: new UntypedFormControl('', Validators.required),
    cpf: new UntypedFormControl('', [Validators.required, FormValidation.cpfValid])
  });

  erroMsg: ErrorMsg = null;
  loading: boolean = false;


  constructor(private auth: AutenticacaoService, private router: Router) { }

  ngOnInit() { }

  formControl(name: string) {
    return this.formulario.controls[name];
  }

  onSubmit() {
    this.loading = true;
    this.auth.esqueciSenha(this.formulario.value).pipe(take(1)).subscribe(
      resp => {
        this.loading = false;
        this.erroMsg = {
          msg: resp.message,
          tipo: resp.erro ? ErrorMsg.ERRO : ErrorMsg.SUCESSO,
          titulo: resp.titulo
        }
      },
      erro => {
        this.loading = false;
        this.actionError(erro)
      }
    );
  }

  fecharErro() {
    this.erroMsg = null;
  }
  actionError(error) {
    this.erroMsg = { tipo: ErrorMsg.ERRO, msg: error.statusText };
  }


}
