import { Component, OnInit } from '@angular/core';
import { AutenticacaoService } from '../shared/autenticacao.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorMsg } from 'src/app/shared/models/errorMsg';

@Component({
  selector: 'app-passo2',
  templateUrl: './passo2.component.html',
  styleUrls: ['./passo2.component.css']
})
export class Passo2Component implements OnInit {

  unidade: any;
  erroMsg:ErrorMsg = null;

  
  constructor(private auth: AutenticacaoService,private router:Router) { }

  ngOnInit() {
    this.unidade = JSON.parse(localStorage.getItem('usuario')).unidade;
  }

  escolherUnidade(id: number) {
    this.auth.loginToken(id).pipe(take(1)).
      subscribe(
        resp => {
          localStorage.setItem('token', resp.token);
          localStorage.setItem('unidade', JSON.stringify(resp.unidade));
          this.router.navigate(['']);
        },
        error => {
          this.actionError(error);
        }
      );
  }

  fecharErro(){
    this.erroMsg = null;
  }
  actionError(error) {
    this.erroMsg = { tipo: ErrorMsg.ERRO, msg: error.statusText };
  }

}
