import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { FormValidation } from '../../validadores/formValidation';

const INPUTGROUP_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputGroupComponent),
  multi: true
};

@Component({
  selector: 'app-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.css'],
  providers: [INPUTGROUP_FIELD_VALUE_ACCESSOR]
})

export class InputGroupComponent implements ControlValueAccessor, OnInit {

  @Input() label: string;
  @Input() id: string = '';
  @Input() type = 'text';
  @Input() control?: UntypedFormControl;
  @Input() disabled: boolean = false;
  @Input() index: number;
  @Output() event = new EventEmitter();

  icone: string;
  bgc: string;
  innerValue: any;
  obrigatorio: boolean = false;
  idHelp: string = `${this.id}help`;

  constructor() { }

  ngOnInit(): void {
    this.labelIndex();

    for (const propertyName in this.control.errors) {
      if (propertyName == "required") {
        this.obrigatorio = true;
      }
    }
    if (this.index == 0) {
      this.icone = 'pi pi-plus';
      this.bgc = 'primary';
    } else {
      this.icone = 'pi pi-minus';
      this.bgc = 'danger';
    }
  }

  labelIndex() {
    if (this.index > 0) {
      this.label = `${this.index + 1}º ${this.label}`;
    }
  }

  click() {
    this.event.emit(this.index);
  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: (_: any) => void = () => { };

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get errorMessage() {
    let m: string[] = [];
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || this.control.dirty)) {
        m.push(FormValidation.getErro(this.label, propertyName, this.control.errors[propertyName]));
      }
    }
    return m.join(", ");
  }

}