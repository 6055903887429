export abstract class EntidadeGenerica {
    public id?: number;
    public nome?: string;
    public ativo?: boolean = true;
    public created?: string;
    public modified?: string;
    public classe?: string;

    constructor() {
    }

    static getDefault(): any {
        return { id: 0, nome: 'Selecione um item' };
    }
    static getDefaultGoup(): any {
        return { "label": "Generico", "items": [{ "id": "0", "nome": "Selecione um item", "texto": "Selecione um item", "observacao": null, "desti": 0, "identificar": true }] };
    }
}

export class Cols {
    public field: string;
    public header: string;
    public ngClass?: string = "";
    public style?: string = "";
    constructor() { }
}

