import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AutenticacaoService, Token } from 'src/app/pages/autenticacao/shared/autenticacao.service';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/pages/usuarios/shared/usuario.model';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { VERSION } from 'src/app/app.api';
import { Permissoes } from 'src/app/shared/models/permissoes';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {


  isLogado$: Observable<boolean>;
  usuario$: Observable<Usuario>;
  items: MenuItem[] = [];
  token: Token;
  user: Usuario;
  display: boolean = false;
  versao: string = VERSION;

  nCalculos: number = 0;
  nUnidades: number = 0;
  nUsuarios: number = 0;

  constructor(private auth: AutenticacaoService, private router: Router) { }

  ngOnInit() {
    this.isLogado$ = this.auth.isLogado;
    this.token = this.auth.getToken();
    this.usuario$ = this.auth.usuario;
    this.usuario$.subscribe(u => {
      this.user = u
      this.carregarMenu();
      this.carregarRelSobre();

    });
  }



  logout() {
    this.auth.logout();
  }

  private carregarRelSobre() {
    if (this.nCalculos == 0) {
      this.auth.getRelSobre().subscribe(nSobre => {
        //@ts-ignore
        this.nCalculos = nSobre.calculos;
        //@ts-ignore
        this.nUnidades = nSobre.unidades;
        //@ts-ignore
        this.nUsuarios = nSobre.usuarios;
      });
    }
  }

  private carregarMenu() {
    this.items = this.menuGeral();
    if (Permissoes.isAdm(this.user.permissao) || this.user.perfil == "2") {
      let item: MenuItem = {
        label: "Administrador",
        icon: "",
        items: []
      }
      for (const p of Permissoes.getAllPermissoes()) {
        if (p.menu && this.user.permissao.includes(p.p)) {
          item.items.push(p.menuItem);
        }
      }
      this.items.push(item);
    }
    this.MeusDados();
  }

  private menuGeral() {
    return [{
      label: "Inicio",
      icon: "",
      routerLink: "/"
    },
    {
      label: "Jecc",
      icon: "",
      items: [
        {
          label: "Cálculos realizados",
          routerLink: "/execucoes"
        },
        {
          label: "Novo cálculo",
          routerLink: "/execucoes/novo"
        },
        {
          label: "Indices",
          icon: "",
          items: [
            {
              label: "IGP-M",
              routerLink: "/indices/igp-m/lista"
            },
            {
              label: "INCC",
              routerLink: "/indices/incc/lista"
            },
            {
              label: "INPC",
              routerLink: "/indices/inpc/lista"
            },
            {
              label: "IPCA",
              routerLink: "/indices/ipca/lista"
            },
            {
              label: "Taxa Legal",
              routerLink: "/indices/taxalegal/lista"
            }
          ]
        },
        {
          separator: true
        }, {
          label: "Requerimento",
          routerLink: '/modelos/jecc'
        }
      ]
    },
    {
      label: "Jecrim",
      icon: "",
      items: [
        {
          label: "Processos",
          routerLink: '/jecrim'
        },
      /*  {
          label: "Editais",
          routerLink: '/editais'
        },*/
        {
          label: "Infrações",
          routerLink: '/infracoes'
        },
        {
          label: "Instituições",
          routerLink: '/instituicoes'
        },
        {
          label: "Valores",
          routerLink: "/jecrim/valores"
        },
        {
          separator: true
        }, {
          label: "Requerimento",
          routerLink: '/modelos/jecrim'
        }
      ]
    },
    {
      label: "Comum",
      icon: "",
      items: [
        {
          label: "Contar prazos",
          routerLink: '/prazos'
        },
        {
          label: "Modelos de requerimento",
          routerLink: '/modelos'
        }
      ]
    }
    ];
  }

  private MeusDados() {
    this.items.push({
      label: "Meus dados",
      icon: "",
      items: [
        { label: "Mudar senha", routerLink: "/meusdados/mudarsenha" }
        , { label: "Minha Conta", routerLink: "/meusdados/minhaconta" }
        , { label: "Minha unidade", routerLink: "/meusdados/minhaunidade" }
      ]
    });
    this.items.push({
      label: "Sobre",
      icon: "",
      command: () => {
        this.display = !this.display;
      }

    });
  }



}
