import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

const EDITOR_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EditorComponent),
  multi: true
};

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
  providers: [EDITOR_FIELD_VALUE_ACCESSOR]
})
export class EditorComponent implements ControlValueAccessor, OnInit {

  @Input() text: any;
  @Input() control?: UntypedFormControl;
  @Input() id: string = '';
  @Input() disabled: boolean = false;
  obrigatorio: boolean = false;

  constructor() { }
 
  ngOnInit(): void {
    if(this.control){
      for (const propertyName in this.control.errors) {
        if (propertyName == "required") {
          this.obrigatorio = true;
        }
      }
    }
  }

  get value() {
    return this.text;
  }

  set value(v: any) {
    if (v !== this.text) {
      this.text = v;
      this.onChangeCb(v);
    }
  }

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: (_: any) => void = () => { };

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
