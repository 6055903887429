import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValidation } from '../../validadores/formValidation';

const INPUTNUMERO_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputNumeroComponent),
  multi: true
};

@Component({
  selector: 'app-input-numero',
  templateUrl: './input-numero.component.html',
  styleUrls: ['./input-numero.component.css'],
  providers: [INPUTNUMERO_FIELD_VALUE_ACCESSOR]
})
export class InputNumeroComponent implements ControlValueAccessor, OnInit {

  @Input() label: string;
  @Input() control?: UntypedFormControl
  @Input() disabled: boolean = false;
  @Input() digitos: number;
  @Input() suffix: string = '';
  @Input() step;
  @Input() id: string = '';
  @Input() min: number = 0;
  idHelp: string = `${this.id}help`;
  innerValue: any;
  obrigatorio: boolean = false;

  constructor() { }

  ngOnInit(): void {
    for (const propertyName in this.control.errors) {
      if (propertyName == "required") {
        this.obrigatorio = true;
      }
    }
  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: (_: any) => void = () => { };

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  get errorMessage() {
    let m: string[] = [];
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || this.control.dirty)) {
        m.push(FormValidation.getErro(this.label, propertyName, this.control.errors[propertyName]));
      }
    }
    return m.join(", ");
  }

}
