import { EntidadeGenerica } from 'src/app/shared/models/entidade-generica';
import * as moment from 'moment';



export class Parcela extends EntidadeGenerica {

    constructor() {
        super();
    }

    static getParcelas(): Parcela[] {
        return _parcela;
    }
    static getParcelaById(id: any = 1): Parcela {
        if (!Number.isInteger(id)) {
            return id;
        }
        return _parcela.filter(d => d.id == id)[0];
    }

}

export class Natureza extends EntidadeGenerica {
    constructor() {
        super();
    }

    static getNaturezaById(id: any = 14): Natureza {
        if (!Number.isInteger(id)) {
            return id;
        }
        return _naturezas.filter(n => n.id == id)[0];
    }

    static getNaturezaByParcela(parcelaId = 1): Natureza[] {
        return _parcelaNatureza[parcelaId].rel.map(function (id) { return Natureza.getNaturezaById(id) });
    }
}

export class Indice extends EntidadeGenerica {

    desde?: string;
    orgao?: string;
    link?: string;
    dt?: string;
    valor?: number;
    corrigido?: boolean;

    constructor() {
        super();
    }

    /**
     * retorna um array de indices
     * @param indices 
     * @returns 
     */
    static getIndices(indices: any[] = _indices): Indice[] {
        return indices.map(function (indice) {
            return { id: indice.id, nome: `${indice.nome} (${indice.orgao}) - desde: ${indice.desde}` }
        });
    }

    /**
     * retorna um indice conforme o id
     * @param id 
     * @returns 
     */
    static getIndice(id: number): Indice {
        return Indice.filterIndices(id)[0];
    }


    static getLink(id: number): string {
        return Indice.filterIndices(id)[0].link;
    }


    /**
     * retorna o indice para o dropdow list
     * @param id 
     * @returns 
     */
    static getIndiceById(id: any): Indice {
        if (!Number.isInteger(id)) {
            return id;
        }
        return Indice.filterIndices(id).map(function (indice) {
            return { id: indice.id, nome: `${indice.nome} (${indice.orgao}) - desde: ${indice.desde}` }
        })[0];
    }

    static filterIndices(id: number): Indice[] {
        let ind: Indice[] = [];
        _indices.forEach(e => {
            if (e.id == id) {
                ind.push(e);
            }
        });
        return ind;
    }


    /**
     * retorna a string da data inicial do indice
     * @param id 
     * @returns 
     */
    static getDtIndice(id: number): string {
        switch (id) {
            case 1: return '1989/06/01';
            case 2: return '1979/04/01';
            case 3: return '1994/01/01';
            case 4: return '1990/01/01';
        }
    }

    static fromJson(json: any): Indice {
        return Object.assign(new Indice(), json);
    }
}

export class MultaParcela extends EntidadeGenerica {
    constructor() {
        super();
    }

    static getMultaParcela(): MultaParcela[] {
        return _multaTipoParcela;
    }

    static getMultaById(id: number): MultaParcela {
        return _multaTipoParcela.filter(m => m.id == id)[0];
    }

    static getNomeMulta(id: number = 1): string {
        return _multaTipoParcela.filter(m => m.id == id)[0].nome;
    }
}


export class Moeda extends EntidadeGenerica {
    de: any;
    ate: any;
    simbolo: string;
    divisor?: number;

    constructor() {
        super();
    }

    static getMoeda(data: any): Moeda {
        if (typeof data == 'object') {
            data = moment(data);
        }
        let moeda: Moeda = moedas[7];
        moedas.forEach(m => {
            if (data.isBetween(m.de, m.ate)) {
                moeda = m;
                return;
            }
        });
        return moeda;
    }

    static getDivisor(data:any):number{
        let id = (Moeda.getMoeda(data).id) - 1;
        let valor = 1;
        for (let index = id; index < moedas.length; index++) {
            valor = valor * moedas[index].divisor;            
        }
        return valor;
    }
}

export class Periodo {
    public indice: Indice;
    public periodo?: string;
    public dtInicial;
    public dtFinal;
}

const _multaTipoParcela: MultaParcela[] = [
    { id: 1, nome: "Multa sobre o valor corrigido" },
    { id: 2, nome: "Multa sobre o valor inicial" }
]

const moedas: Moeda[] = [
    { id: 1, nome: 'Cruzeiro', de: moment('1942-11-01'), ate: moment('1967-02-12'), simbolo: 'Cr$', divisor:1 },
    { id: 2, nome: 'Cruzeiro Novo', de: moment('1967-02-13'), ate: moment('1970-05-14'), simbolo: 'NCr$', divisor:1 },
    { id: 3, nome: 'Cruzeiro', de: moment('1970-05-15'), ate: moment('1986-02-27'), simbolo: 'Cr$', divisor: 1000 },
    { id: 4, nome: 'Cruzado', de: moment('1986-02-28'), ate: moment('1989-01-15'), simbolo: 'Cz$', divisor: 1000 },
    { id: 5, nome: 'Cruzado Novo', de: moment('1989-01-16'), ate: moment('1990-03-15'), simbolo: 'NCr$', divisor: 1 },
    { id: 6, nome: 'Cruzeiro', de: moment('1990-03-16'), ate: moment('1993-07-31'), simbolo: 'Cr$', divisor: 1000 },
    { id: 7, nome: 'Cruzeiro Real', de: moment('1993-08-01'), ate: moment('1994-06-30'), simbolo: 'CR$', divisor: 2750 },
    { id: 8, nome: 'Real', de: moment('1994-07-01'), ate: moment(), simbolo: 'R$', divisor:1 }
];

const _indices: Indice[] = [
    { id: 1, nome: 'IGP-M', desde: '06/1989', orgao: 'FGV', link: 'igpm' },
    { id: 2, nome: 'INPC', desde: '04/1979', orgao: 'IBGE', link: 'inpc' },
    { id: 3, nome: 'IPCA', desde: '01/1994', orgao: 'IBGE', link: 'ipca' },
    { id: 4, nome: 'INCC-DI', desde: '01/1990', orgao: 'FGV', link: 'incc' },
];


const _parcela: Parcela[] = [
    { nome: "HONORÁRIOS", id: 7 },
    { nome: "JUROS DE MORA", id: 2 },
    { nome: "MULTA", id: 5 },
    { nome: "MULTA DIÁRIA", id: 4 },
    { nome: "MULTA 523, § 1º do NCPC", id: 6 },
    { nome: "PARCELA DEDUZIDA", id: 3 },
    { nome: "PRINCIPAL", id: 1 }
];

const _parcelaNatureza = [
    { id: 0, rel: [] },
    { id: 1, rel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 18, 11, 12, 13, 14] },
    { id: 2, rel: [0, 3, 11, 14] },
    { id: 3, rel: [16] },
    { id: 4, rel: [9, 14] },
    { id: 5, rel: [0, 1, 14] },
    { id: 6, rel: [] },
    { id: 7, rel: [5] }
];

const _naturezas: Natureza[] = [
    { nome: "ACORDO", id: 0 },
    { nome: "ACÓRDÃO", id: 1 },
    { nome: "ALUGUEL", id: 2 },
    { nome: "ATUALIZAÇÃO", id: 3 },
    { nome: "CHEQUE", id: 4 },
    { nome: "CONDENAÇÃO EM HONORÁRIOS", id: 5 },
    { nome: "CONDOMÍNIO", id: 6 },
    { nome: "EMPRESTIMO", id: 7 },
    { nome: "FINANCIAMENTO", id: 8 },
    { nome: "LIMINAR", id: 9 },
    { nome: "LUCROS CESSANTES", id: 10 },
    { nome: "PARCELA DEDUZIDA", id: 11 },
    { nome: "PROMISSORIA", id: 12 },
    { nome: "RESSARCIMENTO", id: 13 },
    { nome: "SENTENÇA", id: 14 },
    { nome: "VALOR PAGO", id: 16 },
    { nome: "MULTA", id: 18 }
];