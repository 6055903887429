import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SAJEBA_DB } from '../app.api';
import { ModuleService } from '../shared/bases/base-service/module.service';

@Injectable({
  providedIn: 'root'
})
export class CoreService extends ModuleService<any>{

  constructor(protected http: HttpClient) {
    super(http, `${SAJEBA_DB}/core`, null);
  }


}
