import { Injectable, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, take } from 'rxjs/operators';
import { SAJEBA_DB, USER, UNID, TOKEN } from 'src/app/app.api';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { Usuario } from '../../usuarios/shared/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {

  private _logado = new BehaviorSubject<boolean>(false);
  private _usuario = new BehaviorSubject<Usuario>(new Usuario());
  private _token: boolean = false;
  public unidade: any[] = [];

  constructor(private http: HttpClient, private router: Router) { }

  get isLogado() {
    this.verificarToken();
    return this._logado.asObservable();
  }
  get usuario() {
    return this._usuario.asObservable();
  }
  private verificarToken() {
    try {
      let token: Token = jwt_decode(localStorage.getItem(TOKEN));
      let dtExpired = moment(token.expired_at);
      if (dtExpired.diff(moment()) > 0) {
        if (localStorage.getItem(UNID)) {
          this._logado.next(true);
          this._token = true;
          this._usuario.next(this.getUsuario());
        } else {
          this._logado.next(false);
          this._token = false;
        }
      } else {
        this._logado.next(false);
        this._token = false;
      }
    } catch (e) {
      this._logado.next(false);
      this._token = false;
    }
  }

  get tokenAtivo() {
    this.verificarToken();
    return this._token;
  }

  getToken(): Token {
    let token: Token;
    try {
      token = jwt_decode(localStorage.getItem(TOKEN));
    } catch (erro) {
      token = null;
    }
    return token;
  }

  permissoes(): string[] {
    return JSON.parse(localStorage.getItem(USER)).permissao;
  }

  logout() {
    localStorage.removeItem(USER);
    localStorage.removeItem(UNID);
    localStorage.removeItem(TOKEN);
    this.verificarToken();
    this.router.navigate(['/login']);
  }

  login(user) {
    return this.http.post(`${SAJEBA_DB}/login`, user).pipe(take(1), catchError(this.handleError));;
  }

  esqueciSenha(user) {
    return this.http.post(`${SAJEBA_DB}/recuperarsenha`, user).pipe(take(1), catchError(this.handleError));;
  }

  loginToken(unidade_id: number) {
    return this.http.post(`${SAJEBA_DB}/login/token/${unidade_id}`, null).pipe(take(1), catchError(this.handleError));;
  }

  handleError(error: any): Observable<any> {
    return throwError(error);
  }

  getUsuario(): Usuario {
    try {
      return JSON.parse(localStorage.getItem(USER));

    } catch (e) {
      return null;
    }
  }

  getRelSobre(): Observable<any[]> {
    return this.http.get<any[]>(`${SAJEBA_DB}/usuario/relatorio/sobre`).pipe(take(1), catchError(this.handleError));
  }

  getRelSobre2(): Observable<any[]> {
    return this.http.get<any[]>(`${SAJEBA_DB}/usuario/relatorio/sobre2`).pipe(take(1), catchError(this.handleError));
  }

}

export interface Token {
  id: number;
  nome: string;
  login: string;
  permissao: string[];
  expired_at: string;
  perfil: number;
  cargo: number;
  unidade: number;
}