import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit  {

  @Input('page-title') pageTitle: string;
  @Input('show-button') showButton: boolean = false;
  @Input('button-icon') buttonIcon: string;
  @Input('button-text') buttonText: string;
  @Input('button-link') buttonLink: string;
  @Input('button-color') buttonColor: string;
  @Input() sobreTela: boolean = false;
  @Input() showButtonDialog: boolean = false;
  @Output() sobreATela = new EventEmitter();
  @Output() showDialog = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  sobretela() {
    this.sobreATela.emit();
  }
  show_Dialog() {
    this.showDialog.emit();
  }

}
