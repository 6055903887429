import { Component, OnInit, forwardRef, Input, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { FormValidation } from '../../validadores/formValidation';


const DATE_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateComponent),
  multi: true
};

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css'],
  providers: [DATE_FIELD_VALUE_ACCESSOR]
})
export class DateComponent implements OnInit {
  @Input() label: string;
  @Input() control?: UntypedFormControl;
  @Input() disabled = false;
  @Input() id: string = '';
  @Input() dateFormat: string = 'dd/mm/yy'
  @Input() showTime: boolean = false;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() panelStyleClass: string = "";
  idHelp: string = `${this.id}help`;
  innerValue: Date;
  obrigatorio: boolean = false;


  constructor() { }

  ngOnInit(): void {
    for (const propertyName in this.control.errors) {
      if (propertyName == "required") {
        this.obrigatorio = true;
      }
    }
  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: (_: any) => void = () => { };

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get errorMessage() {
    let m: string[] = [];
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || this.control.dirty)) {
        m.push(FormValidation.getErro(this.label, propertyName, this.control.errors[propertyName]));
      }
    }
    return m.join(", ");
  }
}
