import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  constructor() { }

  @Input() type: string = 'button';
  @Input() label: string = '';
  @Input() icone: string;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() tipo: string = 'secondary';
  @Input() style: string;
  @Input() tooltipPosition: string = 'right';

  ngOnInit() {

  }
  get tooltip() {
    if (this.disabled) {
      return 'Campo(s) obrigatório(s) não preenchido(s)';
    } else {
      return this.label;
    }
  }

  getCorButton() {
    switch (this.tipo) {
      case 'secundary':
        return 'p-button-raised p-button-secondary';
      case 'info':
        return 'p-button-raised p-button-info';
      case 'warn':
        return 'p-button-raised p-button-warning';
      case 'danger':
        return 'p-button-raised p-button-danger';
      case 'excluir':
        return 'p-button-raised p-button-text p-button-plain';
      case 'success':
        return 'p-button-raised p-button-success';
      case 'primary':
        return 'p-button-raised';
      case 'btext':
        return 'p-button-raised p-button-text';
      default:
        return 'p-button-raised p-button-secondary';
    }
  }
}
