import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValidation } from '../../validadores/formValidation';


const INPUT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputLoginComponent),
  multi: true
};


@Component({
  selector: 'app-input-login',
  templateUrl: './input-login.component.html',
  styleUrls: ['./input-login.component.scss'],
  providers: [INPUT_FIELD_VALUE_ACCESSOR]
})
export class InputLoginComponent implements ControlValueAccessor, OnInit {

  @Input() label: string;
  @Input() disponivel: number = -1;
  @Input() loading: boolean = false;
  @Input() control?: UntypedFormControl;
  @Input() disabled: boolean = false;
  @Input() detalhe: string = '';
  @Input() id: string = '';
  @Output() onBlur = new EventEmitter();
  idHelp: string = `${this.id}help`;
  innerValue: any;
  obrigatorio: boolean = false;

  constructor() { }

  mostrarDetalhe(): boolean {
    if (this.detalhe == '') {
      if (this.control.errors) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  ngOnInit(): void {
    for (const propertyName in this.control.errors) {
      if (propertyName == "required") {
        this.obrigatorio = true;
      }
    }
  }

  get isLoading() {
    return this.loading ? "pi pi-spin pi-spinner" : "pi pi-user";
  }

  onInputBlur() {
    this.onBlur.emit(this.value);
  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  get statusLogin() {
    if (this.disponivel < 0) {
      return "";
    } else if (this.disponivel == 0) {
      return "erro";
    } else if (this.disponivel > 0) {
      return "sucesso";
    }
    return "";
  }

  get loginInvalid() {
    if (this.disponivel == 0) {
      return true;
    }
    return false;
  }

  onChangeCb: (_: any) => void = () => { };

  onTouchedCb: (_: any) => void = () => { };

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  get errorMessage() {
    let m: string[] = [];
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || this.control.dirty)) {
        m.push(FormValidation.getErro(this.label, propertyName, this.control.errors[propertyName]));
      }
    }
    return m.join(", ");
  }

}
