import { Component, Input, OnInit } from '@angular/core';
import { Cols } from '../../models/entidade-generica';

@Component({
  selector: 'app-tabela-generica',
  templateUrl: './tabela-generica.component.html',
  styleUrls: ['./tabela-generica.component.css']
})
export class TabelaGenericaComponent implements OnInit {

  @Input() lista: any[] = [];
  @Input() cols: Cols[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
