import { NgModule, LOCALE_ID } from '@angular/core';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import brasil from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { AutenticacaoService } from './pages/autenticacao/shared/autenticacao.service';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './pages/autenticacao/login/login.component';
import { EsqueciSenhaComponent } from './pages/autenticacao/esqueci-senha/esqueci-senha.component';
import { Passo2Component } from './pages/autenticacao/passo2/passo2.component';
import { SharedModule } from './shared/shared.module';
import { RotasGuard } from './guards/rotas.guard';

registerLocaleData(brasil, 'pt');

@NgModule({
  declarations: [AppComponent, LoginComponent, EsqueciSenhaComponent, Passo2Component],
  imports: [
    AppRoutingModule,
    CoreModule,
    SharedModule,
    HttpClientModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    AutenticacaoService,
    AuthGuard,
    RotasGuard
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
