import { Unidade } from '../../unidades/shared/unidade.model';
import { EntidadeGenerica } from 'src/app/shared/models/entidade-generica';
import { Cargo } from '../../cargos/shared/cargo.model';

export class Usuario extends EntidadeGenerica {

    public login?: string;
    public senha?: string;
    public cpf?: string;
    public matricula?: string;
    public cargo?: Cargo;
    public perfil?: string;
    public email?: string;
    public unidade?: Unidade[];
    public permissao?: string[];

    constructor() {
        super();
        this.classe = "usuário";
    }

    get qtdUnidades() {
        return this.unidade.length;
    }

    get unidadeFromTable() {
        return this.unidade.map(u => u.id);
    }
    get cargoFromTable() {
        return this.cargo.nome;
    }

    get unidadeFromDropDown() {
        return this.unidade.map(u => {
            return {
                id: parseInt(u.id.toString()),
                nome: `${u.nome.replace("VARA DO SISTEMA DOS JUIZADOS ESPECIAIS", "VSJ")} - ${u.cidade}`
            }
        });
    }

    get perfilFromDopDown() {
        return Perfis.getPerfilById(this.perfil);
    }

    get cargoFromDropDown() {
        return { id: parseInt(this.cargo.id.toString()), nome: this.cargo.nome }
    }
    static fromJson(json: any): Usuario {
        return Object.assign(new Usuario(), json);
    }
}

export class Perfis extends EntidadeGenerica {

    static getAll(): Perfis[] {
        return _perfis;
    }
    static getPerfilById(id: any = 1): Perfis {
        id = parseInt(id.toString());
        return _perfis.filter(d => d.id == id)[0];
    }

    constructor() {
        super();
    }
}

const _perfis: Perfis[] = [
    { id: 0, nome: 'Selecione um perfil' },
    { id: 2, nome: "Supervisor" },
    { id: 3, nome: "Técnico" },
];