import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

const MULTISELECT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiselectComponent),
  multi: true
};

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.css'],
  providers:[MULTISELECT_FIELD_VALUE_ACCESSOR]
})
export class MultiselectComponent implements OnInit {

  @Input() id: string = '';
  @Input() label: string;
  @Input() options;
  @Input() control?: UntypedFormControl;
  @Input() isReadOnly = false;
  
  idHelp: string = `${this.id}help`;
  innerValue;
  obrigatorio:boolean  = false;
  
  constructor() {}

  ngOnInit(): void {
    for(const propertyName in this.control.errors){
      if (propertyName == "required"){
        this.obrigatorio = true;
      }
    }
  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: (_: any) => void = () => { };

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }

}
