import { AfterViewInit, Directive } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import Inputmask from 'inputmask';


@Directive({
  selector: '[appDatemask]'
})
export class DatemaskDirective implements AfterViewInit {

  constructor(private calendar: Calendar) { }

  ngAfterViewInit(): void {
    new Inputmask( this.getDateMask() ).mask( this.getHTMLInput() );
  }

  getHTMLInput(): HTMLInputElement {
    return this.calendar.el.nativeElement.querySelector('input');
  }

  getDateMask(): string {
    if (this.calendar.timeOnly) {
      return '99:99';
    } else if (this.calendar.showTime) {
      return '99/99/9999 99:99';
    } else {
      return '99/99/9999';
    }
  }

}
