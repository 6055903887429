import { Pipe, PipeTransform } from '@angular/core';
import {Moeda} from '../pages/execucoes/shared/MixCalculo';
import { formatCurrency } from '@angular/common';
import * as moment from 'moment';

@Pipe({name:'moeda'})
export class ExibirMoedaPipe implements PipeTransform{
    transform(valor:number = 0, data:any = moment()):string {
        return formatCurrency(valor,'pt',Moeda.getMoeda(data).simbolo);
    }
}