import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AutenticacaoService } from '../pages/autenticacao/shared/autenticacao.service';
import { Erros } from './Erros';

@Injectable() 
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private authService:AutenticacaoService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('token');

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError(erro => {
                if (erro.status === 403) {
                    this.authService.logout();
                    location.reload();
                }
                return throwError(this.onError(erro));
            }
            ));
    }
    
    private onError(erro:HttpErrorResponse):HttpErrorResponse{
        return new Erros(erro);
    }

}