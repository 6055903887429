import { MenuItem } from "primeng/api";

export class Permissoes {
    public static USUARIO_LOCAL = 'USUARIOS_LOCAL';
    public static USUARIO_ALL = 'USUARIOS_ALL';
    public static UNIDADES = 'UNIDADES';
    public static CORRIGIR_INDICES = 'CORRIGIR_INDICES';
    public static CARGOS = 'CARGOS';
    public static CIDADES = 'CIDADES';
    public static CIDADAOS = 'CIDADAOS';
    public static ADM_LOG = 'ADM_LOG';
    public static EXECUCAO_EXCLUIR = 'EXECUCAO_EXCLUIR';
    public static REINICIAR_SENHA = 'REINICIAR_SENHA';
    public static PRAZOS_EXCLUIR = 'PRAZOS_EXCLUIR';
    public static REATIVAR = 'REATIVAR';


    static isAdm(permissoes: string[]) {
        for (const i of permissoes) {
            for (const p of _permissoes) {
                if (i == p.p) {
                    return true;
                }
            }
        }
        return false;
    }

    static getPermissoes(): string[] {
        return _permissoes.map(p => p.p);
    }

    static getAllPermissoes() {
        return _permissoes;
    }

}

const _permissoes: { menu: boolean, p: string, menuItem?: MenuItem }[] = [
    { menu: true, p: Permissoes.USUARIO_LOCAL, menuItem: { label: "Usuários", routerLink: "/usuarios" } },
    { menu: false, p: Permissoes.USUARIO_ALL },
    { menu: true, p: Permissoes.UNIDADES, menuItem: { label: "Unidades", routerLink: "/unidades" } },
    { menu: true, p: Permissoes.CORRIGIR_INDICES, menuItem: { label: "Corrigir índice", routerLink: "/indices/corrigir" } },
    { menu: true, p: Permissoes.CARGOS, menuItem: { label: "Cargos", routerLink: "/cargos" } },
    { menu: true, p: Permissoes.CIDADES, menuItem: { label: "Cidades", routerLink: "/cidades" } },
    { menu: true, p: Permissoes.CIDADAOS, menuItem: { label: "Cidadãos", routerLink: "/cidadaos" } },
    { menu: true, p: Permissoes.ADM_LOG, menuItem: { label: "Logs", routerLink: "/adm/logs" } },
    { menu: false, p: Permissoes.EXECUCAO_EXCLUIR },
    { menu: false, p: Permissoes.REINICIAR_SENHA },
    { menu: false, p: Permissoes.PRAZOS_EXCLUIR },
    { menu: true, p: Permissoes.REATIVAR, menuItem: { label: "Reativar", routerLink: "/adm/reativar" } },

]