import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tabela-indice',
  templateUrl: './tabela-indice.component.html',
  styleUrls: ['./tabela-indice.component.css']
})
export class TabelaIndiceComponent implements OnInit {

  constructor() { }

  @Input() nome: string = '';
  @Input() objs: any[] = null;
  @Output() salvar = new EventEmitter();
  @Input() tbLoading: boolean = false;
  @Input() btLoading: boolean = false;
  cloneEditando: any = null;
  salvo: boolean = false;

  ngOnInit(): void {

  }

  salvando(obj, nome) {
    this.salvo = true;
    this.salvar.emit({ nome: nome.replace('-', '').replace(' ', ''), obj: obj });
  }

  editando(event) {
    this.salvo = false;
    this.cloneEditando = { ...event.data };
  }

  focusOut(event) {
    if (!this.salvo) {
      this.objs[event.index] = this.cloneEditando;
    }
  }

}
