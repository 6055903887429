import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { P404Component } from './components/p404/p404.component';
import { SharedModule } from '../shared/shared.module';

//PrimeNG
import {MenubarModule} from 'primeng/menubar';

@NgModule({
  declarations: [
    NavbarComponent, 
    HomeComponent, 
    P404Component
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    MenubarModule

  ],
  exports:[
    NavbarComponent, 
    HomeComponent,
    P404Component,
    BrowserModule,
    BrowserAnimationsModule
  ],
  providers:[]
})
export class CoreModule { }
