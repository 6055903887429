import { Component, OnInit } from '@angular/core';
import { UNID } from 'src/app/app.api';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css']
})
export class CabecalhoComponent implements OnInit {

  constructor() { }
  public unidade = JSON.parse(localStorage.getItem(UNID));

  ngOnInit(): void {
  }

}
