import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/pages/usuarios/shared/usuario.model';
import { Unidade } from 'src/app/pages/unidades/shared/unidade.model';
import { SAJEBA_DB, UNID, USER } from 'src/app/app.api';
import { Cols } from 'src/app/shared/models/entidade-generica';
import { CoreService } from '../../core.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  usuario: Usuario;
  unidade: Unidade;
  detalhesJecrims: any[] = [];
  detalhesJeccs: any[] = [];
  public expandedRowsJecrim = {};
  public expandedRowsJecc = {};
  public listadeItens = [
    { id: 1, nome: "Disponibilidade das instituições", crime: true },
    { id: 2, nome: "Indices não atualizados", crime: false }
  ];

  colunasJecrim: any[] = [];
  colunasJecc: any[] = [];




  constructor(protected service: CoreService) {
  }

  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem(USER));
    this.unidade = JSON.parse(localStorage.getItem(UNID));

  }


  get mostrarTabela(): boolean {
    return this.usuario.login == 'eminascimento' || this.usuario.login == 'marvsilva';
  }

  getColunas(id) {
    switch (id) {
      case 1:
        return [
          { field: 'nome', header: 'Nome' },
          { field: 'atual', header: 'Atual' },
          { field: 'maximo', header: 'Máximo' }
        ];
      case 2:
        return [
          { field: 'nome', header: 'Nome' },
          { field: 'meses', header: 'Meses' },
        ];
    }
  }

  get jecrims() {
    return this.getItemByNat(true);
  }

  get jeccs() {
    return this.getItemByNat(false);
  }


  buscarDetalhesJecc(id, expanded) {
    if (!expanded) {
      this.colunasJecc = this.getColunas(id);
      this.service.getGeneric(`${SAJEBA_DB}/core/${id}`).subscribe(
        lista => {
          if (id == 2) {
            this.detalhesJeccs = [];
            lista.forEach(l => {
              if (l.meses != null) {
                this.detalhesJeccs.push(l);
              }
            });
          } else {
            this.detalhesJeccs = lista;
          }

        },
        erro => {
          console.log(erro);
        }
      );
    }
  }

  buscarDetalhesJecrim(id, expanded) {
    if (!expanded) {
      this.colunasJecrim = this.getColunas(id);
      this.service.getGeneric(`${SAJEBA_DB}/core/${id}`).subscribe(
        lista => {
          this.detalhesJecrims = lista;
        },
        erro => {
          console.log(erro);
        }
      );
    }
  }

  getItemByNat(crime: boolean): any {
    let list: any[] = [];
    this.listadeItens.forEach(e => {
      if (e.crime == crime) {
        list.push(e);
      }
    });

    return list;
  }

}
