import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SAJEBA_DB } from 'src/app/app.api';
import { ErrorMsg } from '../../models/errorMsg';


@Component({
  selector: 'app-errormsg',
  templateUrl: './errormsg.component.html',
  styleUrls: ['./errormsg.component.css']
})
export class ErrormsgComponent implements OnInit {

  constructor() { }
  public link: string = '';
  @Input() msg: ErrorMsg = { tipo: ErrorMsg.SUCESSO, msg: "fasdfasdf", titulo: "titulo" };
  @Output() fechar = new EventEmitter();

  ngOnInit() {
    this.link = `${SAJEBA_DB}/gerar_pdf/${this.msg?.codigo}`;
  }

  aofechar() {
    this.fechar.emit('');
  }

  icone() {
    if (this.msg.tipo == ErrorMsg.SUCESSO) {
      return 'pi pi-check'
    } else if (this.msg.tipo == ErrorMsg.ERRO) {
      return 'pi pi-exclamation-triangle';
    }
  }

}

