import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

export class Erros extends HttpErrorResponse {
    headers: HttpHeaders;
    status: number;
    statusText: string;
    url: string;
    message: string;

    constructor(private httpErro: HttpErrorResponse) {
        super(httpErro);
        this.customizarErro();
    }

    private customizarErro() {
        this.message = this.httpErro.message;
        this.status = this.httpErro.status;
        this.url = this.httpErro.url;
        if (this.httpErro.error != null && this.httpErro.error.hasOwnProperty('message')) {
            this.statusText = this.httpErro.error.message;
        } else {
            switch (this.httpErro.status) {
                case 500:
                    this.statusText = "Não foi possível acessar o banco de dados, tente novamente mais tarde.";
                    break;
                case 504:
                    this.statusText = "Erro no servidor, tente novamente mais tarde.";
                    break;
                default:
                    this.statusText = this.httpErro.statusText;
                    break;
            }
        }

    }

}