import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './core/components/home/home.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { P404Component } from './core/components/p404/p404.component';
import { AuthGuard } from './guards/auth.guard';
import { RotasGuard } from './guards/rotas.guard';
import { EsqueciSenhaComponent } from './pages/autenticacao/esqueci-senha/esqueci-senha.component';
import { LoginComponent } from './pages/autenticacao/login/login.component';
import { Passo2Component } from './pages/autenticacao/passo2/passo2.component';
import { Permissoes } from './shared/models/permissoes';

const routes: Routes = [
  {
    path: '', component: NavbarComponent,
    children: [
      { path: '', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'modelos', loadChildren: () => import('./pages/modelos/modelos.module').then(m => m.ModelosModule) },
      { path: 'execucoes', loadChildren: () => import('./pages/execucoes/execucoes.module').then(m => m.ExecucoesModule) },
      { path: 'usuarios', loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosModule), canActivate: [RotasGuard], data: { role: Permissoes.USUARIO_LOCAL } },
      { path: 'unidades', loadChildren: () => import('./pages/unidades/unidades.module').then(m => m.UnidadesModule), canActivate: [RotasGuard], data: { role: Permissoes.UNIDADES } },
      { path: 'cargos', loadChildren: () => import('./pages/cargos/cargos.module').then(m => m.CargosModule), canActivate: [RotasGuard], data: { role: Permissoes.CARGOS } },
      { path: 'cidadaos', loadChildren: () => import('./pages/cidadaos/cidadaos.module').then(m => m.CidadaosModule), canActivate: [RotasGuard], data: { role: Permissoes.CIDADAOS } },
      { path: 'cidades', loadChildren: () => import('./pages/cidades/cidades.module').then(m => m.CidadesModule), canActivate: [RotasGuard], data: { role: Permissoes.CIDADES } },
      { path: 'indices', loadChildren: () => import('./pages/indices/indices.module').then(m => m.IndicesModule) },
      { path: 'meusdados', loadChildren: () => import('./pages/meusdados/meusdados.module').then(m => m.MeusDadosModule) },
      { path: 'instituicoes', loadChildren: () => import('./pages/instituicoes/instituicoes.module').then(m => m.InstituicoesModule) },
      { path: 'jecrim', loadChildren: () => import('./pages/jecrim/jecrim.module').then(m => m.JecrimModule) },
      { path: 'editais', loadChildren: () => import('./pages/editais/editais.module').then(m => m.EditaisModule) },
      { path: 'prazos', loadChildren: () => import('./pages/prazos/prazos.module').then(m => m.PrazosModule) },
      { path: 'infracoes', loadChildren: () => import('./pages/infracoes/infracoes.module').then(m => m.InfracoesModule) },
      { path: 'adm', loadChildren: () => import('./pages/adm/adm.module').then(m => m.AdmModule), canActivate: [AuthGuard] }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '', component: LoginComponent, children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
    ]
  },
  { path: 'esqueci-senha', component: EsqueciSenhaComponent },
  { path: 'escolher-unidade', component: Passo2Component },
  { path: 'requerimentos', loadChildren: () => import('./pages/req-publico/req-publico.module').then(m => m.ReqPublicoModule) },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
